<template>
    <v-list-item link v-if="planningModuleActive" @click="handleClick">
        <v-list-item-icon>
            <v-icon>mdi-calendar-multiple-check</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Naar planning</v-list-item-title>
    </v-list-item>
</template>
  
<script>
import ModuleType from "@/shared/enums/moduleType"
import routeNames from "@/router/RouteNames"
  
export default {
    name: 'PlanningHeaderAction',
    props: {
        query: {
            type: Object,
            required: true
        }
    },
    computed: {
        planningModuleActive() {
            return this.$store.getters["organizationModule/organizationHasModule"]([ModuleType.PLANNING])
        }
    },
    data() {
        return {
            planningsRoute: {name: routeNames.PLANNING},
        }
    },
    methods: {
        handleClick() {
            if (this.planningModuleActive) {
                this.$router.push({
                    ...this.planningsRoute,
                    query: this.query
                })
            }
        }
    }
}
</script>
  